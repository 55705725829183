import * as React from "react";

import "./subscribeButtons.scss";
import { SubscribeSection } from "./SubscribeSection";

export interface Props {
    title?: string;
    subtitle?: string;
    mobileImageSrc?: string;
    backgroundColor?: string;
    newCustomersOnly?: React.ReactNode;

    couponCode?: string;
}

export const Subscribe: React.SFC<Props> = ({
    couponCode,
    mobileImageSrc,
    subtitle,
    title,
    backgroundColor,
    newCustomersOnly,
    ...props
}) => {
    const planData = [{
        active: true,
        amount: 5999,
        amountDecimal: 5999,
        billingScheme: "per_unit",
        created: 1581636013,
        currency: "usd",
        planId: "plan_GgfEYDklV8lyqO",
        id: "plan_GgfEYDklV8lyqO",
        interval: "year" as const,
        intervalCount: 1,
        interval_count: 1,
        livemode: false,
        metadata: {},
        name: "Web Annual US",
        nickname: "Web Annual US",
        object: "plan",
        product: "prod_GgfCYRVcQ2BlBY",
        trialPeriodDays: 30,
        usageType: "licensed",
    }];
    return (
        <SubscribeSection
            stripeProductId={"prod_GgfCYRVcQ2BlBY"}
            stripePlans={planData}
            extraStrings={{
                subscribePage: {
                    title,
                    subtitle
                }
            }}
            mobileImageSrc={mobileImageSrc}
            couponCode={couponCode}
            backgroundColor={backgroundColor}
            newCustomersOnly={newCustomersOnly}
            filter={plan =>
                plan.interval === "year" &&
                (plan.intervalCount === 1 || plan.interval_count === 1)
            }
        />
    );
};
